<template>
  <section id="dashboard-analytics">
    <b-container fluid>
      <b-img
        :src="require('@/assets/images/main/main_banner_230215.jpg')"
        fluid-grow
      />
    </b-container>
    <!-- 공지사항-->

    <div
      class="notice-summary mx-1 mt-1 mb-1"
      v-if="notice.length"
    >
      <div class="d-flex justify-content-between">
        <div class="mb-25">
          <h2 class="content-header-title float-left pr-1 mb-0 border-0">
            AMPFRI 26대 총동문회
          </h2>
        </div>
        <div class="text-right">
          <b-link
            to="/post/list/18"
            class="text-secondary font-weight-bolder"
          >
            <feather-icon
              size="38"
              icon="PlusIcon"
            />
          </b-link>
        </div>
      </div>

      <div
        class="d-flex justify-content-between font-medium-1"
        v-for="data in notice"
        :key="data.id"
      >
        <!-- :href="`/post/${data.id}`" -->
        <!-- :to="{ name: 'post-detail', params: { postid: data.id } }" -->
        <div class="my-50 ml-25">
          <b-link
            :to="{ name: 'post-detail', params: { postid: data.id } }"
            class="text-secondary"
          >
            [공지] {{ data.title }}
          </b-link>
        </div>
      </div>
    </div>

    <!-- 버튼 영역 -->
    <div class="d-flex justify-content-center flex-wrap">
      <div class="d-flex align-items-center mr-0 ml-50 my-50">
        <b-link to="/menu/6">
          <b-img
            :src="require('@/assets/images/main/ampfri_intro.png')"
            width="90"
            height="90"
          />
        </b-link>
      </div>
      <div class="d-flex align-items-center mr-0 ml-50 my-50">
        <b-link to="/menu/118">
          <b-img
            :src="require('@/assets/images/main/admission.png')"
            width="90"
            height="90"
          />
        </b-link>
      </div>
      <div class="d-flex align-items-center mr-0 ml-50 my-50">
        <b-link to="/user/books">
          <b-img
            :src="require('@/assets/images/main/books.png')"
            width="90"
            height="90"
          />
        </b-link>
      </div>
    </div>
    <div class="d-flex justify-content-center flex-wrap">
      <div class="d-flex align-items-center mr-0 ml-50 my-50">
        <b-link to="/post/list/14">
          <b-img
            :src="require('@/assets/images/main/event_schedule.png')"
            width="90"
            height="90"
          />
        </b-link>
      </div>
      <div class="d-flex align-items-center mr-0 ml-50 my-50">
        <b-link to="/post/list/21">
          <b-img
            :src="require('@/assets/images/main/photo_gallery.png')"
            width="90"
            height="90"
          />
        </b-link>
      </div>
      <div class="d-flex align-items-center mr-0 ml-50 my-50">
        <b-link to="/post/list/18">
          <b-img
            :src="require('@/assets/images/main/notice.png')"
            width="90"
            height="90"
          />
        </b-link>
      </div>
    </div>
    <!-- 메인 배너 -->
    <b-row class="no-gutters mt-1">
      <b-col cols="12">
        <banner-swiper />
      </b-col>
    </b-row>
    <!-- <b-row class="match-height">
      <b-col lg="6">
        <analytics-timeline :data="data.timeline" />
      </b-col>
    </b-row> -->
  </section>
</template>

<script>
  import { BRow, BCol, BLink, BImg, BContainer } from 'bootstrap-vue';
  // import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue';

  import { kFormatter } from '@core/utils/filter';
  // import AnalyticsTimeline from './AnalyticsTimeline.vue';
  import BannerSwiper from './BannerSwiper.vue';

  import 'swiper/css/swiper.css';

  export default {
    components: {
      BRow,
      BCol,
      BLink,
      BImg,
      BContainer,
      // AnalyticsTimeline,
      BannerSwiper,
      // StatisticCardVertical,
    },
    data() {
      return {
        notice: [],
      };
    },
    created() {
      this.getNotice();
    },
    methods: {
      kFormatter,
      async getNotice() {
        this.$http.get(`/post/top/18/1`).then(res => {
          this.notice = res.data.list;
        });
      },
    },
  };
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/swiper.scss';
</style>
<style lang="css"></style>
