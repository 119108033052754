<template>
  <b-card
    no-body
    v-if="swiperData.length"
  >
    <div>
      <!-- images -->
      <swiper
        class="swiper-autoplay rounded"
        :options="swiperOptions"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      >
        <swiper-slide
          v-for="data in swiperData"
          :key="data.id"
        >
          <b-link
            :href="data.openUrl"
            target="_blank"
          >
            <b-img
              :src="data.image"
              fluid
              class="swiper-lazy"
            />
          </b-link>
        </swiper-slide>
        <div
          slot="pagination"
          class="swiper-pagination"
        />
      </swiper>
    </div>
  </b-card>
</template>

<script>
  import { BCard, BImg, BAvatar, BCardText, BLink } from 'bootstrap-vue';
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
  import 'swiper/css/swiper.css';

  export default {
    components: {
      Swiper,
      SwiperSlide,
      BCard,
      BAvatar,
      BImg,
      BLink,
      BCardText,
    },
    data() {
      return {
        dataList: [],
        swiperOptions: {
          lazy: true,
          spaceBetween: 30,
          centeredSlides: true,
          autoplay: {
            delay: 4000,
            disableOnInteraction: false,
          },
          pagination: {
            el: '.swiper-pagination',
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        },
        swiperData: [],
        /* eslint-disable global-require */
        // ['id', 'title', 'openUrl', 'image', 'location']
        // swiperData: [
        //   {
        //     img: require('@/assets/images/banner/robot.jpg'),
        //     link: 'https://robot.topint.co.kr',
        //   },
        //   {
        //     img: require('@/assets/images/banner/toporder.jpg'),
        //     link: 'https://topint.co.kr',
        //   },
        // ],
        /* eslint-disable global-require */
      };
    },
    created() {
      this.getBanner();
      // this.$http.get('/banner/current').then(res => {
      //   // console.log('data:', res.data);
      //   this.dataList = res.data;
      // });
    },
    methods: {
      async getBanner() {
        this.$http.get('/banner/current').then(res => {
          // console.log('data:', res.data);
          this.swiperData = res.data;
        });
      },
    },
  };
</script>
